
import { defineComponent, ref } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import moment from 'moment';

export default defineComponent({
  name: 'batchView',
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const batchId = route.params.id;
    return { batchId };
  },
  data() {
    return {
      tableHeader: [
        {
          name: 'days',
          key: 'Days',
          sortable: true,
        },
        // {
        //   name: "Location",
        //   key: "location",
        //   sortable: true,
        // },
        {
          name: 'Start Time',
          key: 'startTime',
          sortable: true,
        },
        {
          name: 'End Time',
          key: 'endTime',
          sortable: true,
        },
        {
          name: 'Daily Hours',
          key: 'dailyHours',
          sortable: false,
        },
        // {
        //     name: 'Lead Trainer',
        //     key: 'leadTrainer',
        //     sortable: false,
        // },
        // {
        //     name: 'Associate Trainer',
        //     key: 'associateTrainer',
        //     sortable: false,
        // },
      ],
      moment: '' as any,
      today: '' as any,
      batchInfoData: [],
      search: '',
      tableData: [],
      data: {},
      load: false,
      tabIndex: ref(0),
      entityInfos: [],
      token: '' as any,
    };
  },
  async created() {
    this.load = true;
    this.moment = moment;
    this.today = moment().format('YYYY-MM-DD');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
        this.token = urlParams.get('token');
        console.log(this.token);
    
    this.moment = moment;
    await this.getBatchInfo();
    //Object.assign(this.tableData, this.batchInfoData);
  },
  methods: {
    async getBatchInfo() {
      await ApiService.getMobile('batch/show/' + this.batchId, this.token)
        .then((response) => {
          this.load = false;
          this.batchInfoData = response.data.data;
          console.log(this.batchInfoData);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
